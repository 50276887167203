import React from 'react';
import Callout from '../components/Callout/Callout';
import CalloutLinks from '../components/CalloutLinks/CalloutLinks';
import Hero from '../components/Hero/Hero';
import ImageText from '../components/ImageText/ImageText';
import { GlobalStyles } from '../styles/Global.styled';

const IndexPage = () => {
  return (
    <>
      <GlobalStyles />
      <main>
        <Hero />
        <ImageText />
        <Callout 
          heading={`Text us at the booth and set-up a time to stop by!`}
        />
        <CalloutLinks />
      </main>
    </>
  )
}

export default IndexPage;
