import React from 'react'
import { StyledCalloutLinks } from './CalloutLinks.styled'

const CalloutLinks = () => {
    const columnCopy = [
        {
            name: "Group Travel Odyssey",
            phoneMessage: "Text The GTO Booth",
            phoneNumber: "517-414-1637",
        },
        {
            name: "WeTravel",
            phoneMessage: "Text The WeTravel Booth",
            phoneNumber: "305-394-7061",
        }
    ]
    return (
        <StyledCalloutLinks>
            {columnCopy.map(copy => {
                return (
                    <div className={`col`}>
                        <p>
                            <span>Find Out More About</span>
                            {copy.name}
                        </p>
                        <a 
                            className={'button'}
                            href={`sms:${copy.phoneNumber}?&body=I%27d%20like%20to%20set%20up%20a%20time%20to%20come%20by%20the%20booth`}
                        >
                            {copy.phoneMessage}
                        </a>
                    </div>
                );
            })}
        </StyledCalloutLinks>
    )
}

export default CalloutLinks