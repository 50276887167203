import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { ImageColumn, StyledImageText, TextColumn } from './ImageText.styled';
import highFivePhoto from '../../images/people-high-fiving.png';

const ImageText = () => {
    const copy = [
        {
            para: "WeTravel and Group Travel Odyssey are all about creating a stronger and more cohesive group travel community.  We believe we are all more successful when we work together to elevate group travel!",
        },
        {
            para: "So… Stop by and say hello!"
        },
        {
            para: "While you\’re there, ask us how <u>we work together</u> to make planning group trips simple and efficient.",
        }
    ]
    return (
        <StyledImageText>
            <TextColumn>
                <h2>
                    WeTravel and GTO work together to help your group business thrive!
                </h2>
                {copy.map(copyItem => {
                    return (
                        <p 
                            dangerouslySetInnerHTML={{ __html: copyItem.para }}
                        />
                    );
                })}
            </TextColumn>
            <ImageColumn>
                <StaticImage
                    src={`../../images/people-high-fiving.png`}
                    layout="full-width"
                    aspectRatio={16 / 9}
                />
            </ImageColumn>
        </StyledImageText>
    )
}

export default ImageText;
