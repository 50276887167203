import styled from "styled-components";
import { Container } from "../../styles/Global.styled";

export const StyledHero = styled.section`
    position: relative;
    height: 60vh;
    min-height: 500px;
    padding: 1rem 0 3rem;
    background: var(--color-blue);
    color: var(--color-white);

    @media (min-width: 700px) {
        padding: 3rem 0 4rem;
    }

    ${Container} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        text-align: center;
    }

    h1 {
        margin: 2rem 1.75rem;
        font-size: 2.5rem;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1.2em;

        @media (min-width: 700px) {
            margin: 1rem 0;
            font-size: 5rem;
        }
    }

    p {
        margin: 0 2.25rem;
        max-width: 650px;
        font-size: 1.15rem;
        font-weight: 500;
        letter-spacing: 1px;

        @media (min-width: 400px) {
            margin: 0 2rem;
            font-size: 1.35rem;
        }

        @media (min-width: 700px) {
            margin: 0 auto;
            font-size: 1.5rem;
        }
    }

    svg {
        position: absolute;
        left: 0;
        bottom: -100px;
    }
`;

export const HeroDates = styled.div`
    margin-top: 2rem;
    font-size: .850rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media (min-width: 700px) {
        margin-top: 4rem;
        font-size: 1rem;
    }
`;

export const HeroLogos = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 1.250rem;
    max-width: 900px;

    @media (min-width: 700px) {
        margin: 0 auto 2rem;
    }
`;

export const HeroLogo = styled.a`
    display: inline-block;
    margin-right: 5rem;
    max-width: 220px;

    @media (min-width: 700px) {
        max-width: 240px;
    }

    &:last-child {
        margin-right: 0;
    }

    img {
        filter: brightness(100);
    }
`;