import styled from 'styled-components';

export const StyledCalloutLinks = styled.section`
    display: flex;
    flex-direction: column;

    @media(min-width: 700px) {
        flex-direction: row;
    }

    .col {
        padding: var(--column-padding-sm);
        background: var(--color-green);
        
        border-top: 3px solid var(--color-white);
        text-align: center;

        &:first-child {
            /* border-bottom: none; */
        }

        @media (min-width: 700px) {
            padding: var(--column-padding-lg);
            width: 50%;
            border-bottom: 3px solid var(--color-white);

            &:first-child {
                border-right: 3px solid var(--color-white);
            }
        }

        p {
            margin: 0 auto 2rem;
            color: var(--color-white);
            font-size: 2rem;
            font-weight: 500;
            letter-spacing: 1px;

            @media (min-width: 700px) {
                font-size: 3rem;
            }

            span {
                display: block;
                font-size: 0.850rem;
                text-transform: uppercase;

                @media (min-width: 700px) {
                    font-size: 1rem;
                }
            }
        }
    }
`;