import React from 'react'
import { Container } from '../../styles/Global.styled';
import { HeroDates, HeroLogo, HeroLogos, StyledHero } from './Hero.styled';
import GTOLogo from "../../images/gto-logo.svg";
import { StaticImage } from 'gatsby-plugin-image';

const Hero = () => {
  return (
    <StyledHero>
        <Container>

            <HeroLogos>
              <HeroLogo
                href="https://www.wetravel.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StaticImage
                  src="../../images/wetravel-logo.png"
                  alt="WeTravel Logo"
                />
              </HeroLogo>
              <HeroLogo 
                href="https://grouptravelodyssey.com/" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src={GTOLogo} alt="Group Travel Odyssey Logo" />
              </HeroLogo>
            </HeroLogos>
            <h1>There’s No “I” at IPW!</h1>
            <p>
              WeTravel and GTO are teaming up at Booth #1036! Stop by to see how we’ve joined forces to help operators and suppliers manage groups seamlessly and efficiently.
            </p>
            <HeroDates>
                June 04 - 08, 2022
            </HeroDates>
        </Container>
    </StyledHero>
  )
}

export default Hero;
