import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    :root {
        --color-green: #4BD147;
        --color-blue: #3090E0;
        --color-white: #FFF;
        --column-padding-sm: 3rem 2rem;
        --column-padding-lg: 10rem 4rem;
    }

    html {
        box-sizing: border-box;
    }
    
    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        padding: 0;
        /* background: #f3f4f5; */
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
               Roboto, Oxygen-Sans, Ubuntu, Cantarell,
               "Helvetica Neue", sans-serif;
        font-size: 16px;
        line-height: 1.618;
    }

    img {
        height: auto;
        width: 100%;
    }

    .button {
        display: inline-block;
        padding: 1rem 3rem;
        background: var(--color-white);
        color: var(--color-blue);
        font-size: 0.850rem;
        font-weight: bold;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: uppercase;
    }
`;

export const Container = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    width: 96%;
`;
