import React from 'react';
import { Container } from '../../styles/Global.styled';
import { StyledCallout } from './Callout.styled';

const Callout = ({
    heading,
    phone,
    phoneNumber,
}) => {
    return (
        <StyledCallout>
            <Container>
                {heading && (
                    <h2>
                        {heading}
                    </h2>
                )}
                {/* {phone && (
                    <a 
                        className={'button'}
                        href={`sms:${phoneNumber}&amp;body=I%27d%20like%20to%20set%20up%20a%20time%20to%20come%20by%20the%20booth`}
                    >
                        {phone}
                    </a>
                )} */}
            </Container>
        </StyledCallout>
    )
}

export default Callout;