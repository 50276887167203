import styled from 'styled-components';

export const StyledImageText = styled.section`
    display: flex;
    flex-direction: column;

    @media (min-width: 700px) {
        flex-direction: row;
    }
`;

export const TextColumn = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--column-padding-sm);

    @media (min-width: 700px) {
        padding: var(--column-padding-lg);
        width: 50%;
    }

    p {
        margin: 0 0 1.250rem;
        max-width: 720px;
        font-size: 1.250rem;
        line-height: 1.618;
    }

    h2 {
        margin: 0 0 2rem;
        font-size: 1.75rem;
        font-weight: 500;
        line-height: 1.5;

        @media (min-width: 700px) {
            max-width: 600px;
            font-size: 2rem;
        }
    }
`;

export const ImageColumn = styled.section`
    width: 100%;
    
    @media (min-width: 700px) {
        width: 50%;
    }
    
    .gatsby-image-wrapper {
        height: 100%;
    }

    img {
        object-position: right;
    }
`;