import styled from 'styled-components';
import { Container } from '../../styles/Global.styled';

export const StyledCallout = styled.section`
    display: flex;
    padding: 3.25rem 0;
    background: var(--color-blue);

    ${Container} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h2 {
        margin: 0 auto;
        max-width: 400px;
        color: var(--color-white);
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.4em;
        text-align: center;

        @media (min-width: 700px) {
            max-width: 720px;
            font-size: 2.5rem;
        }
    }

    p {
        margin: 1.250rem 0 0;
        color: var(--color-white);
        font-size: 1.50rem;
        text-align: center;
    }

    .button {
        margin-top: 2rem;
    }
`;